<!-- NOTE replace with BaseStrapiImageComponent -->
<template>
  <BaseStrapiImageComponent
    v-if="!isSvg"
    :image="image"
  >
    <template
      #caption
      v-if="image?.caption && showCaption"
    >
      <div :class="$style.caption">
        {{ image?.caption }}
      </div>
    </template>
  </BaseStrapiImageComponent>

  <BaseFigureComponent
    v-else
  >
    <img
      :src="baseStrapiGetAbsoluteFileUrl(image?.url)"
      :alt="image?.alternativeText"
    />
    <template
      #caption
      v-if="image?.caption && showCaption"
    >
      <div :class="$style.caption">
        {{ image?.caption }}
      </div>
    </template>
  </BaseFigureComponent>
</template>

<script setup lang="ts">
const props = defineProps({
  image: {
    type: Object,
    required: true,
  },
  showCaption: {
    type: Boolean,
    default: false,
  }
})

const isSvg = props?.image?.ext?.includes('svg')
</script>

<style module>
.caption {
  composes: font-size-default from global;
  text-align: center;
  margin-top: calc(var(--lh) / 2);
}
</style>
